import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { renderRoutes } from "react-router-config";
import moment from "moment";
import smoothscroll from "smoothscroll-polyfill";
import classNames from "classnames";
import HeaderPlain from "../../components/HeaderPlain";
import MiniLeftDrawer from "../../components/MiniLeftDrawer";
import { withStyles } from "@mui/styles";
import "./index.css";
import { renewalActions } from "../../actions";
import SessionExpireDialog from "../../components/SessionExpireDialog";
import { productActions, userActions, appActions, clientAndPolicyListingActions } from "../../actions";
import assets from "../../assets";
import FirstPageDialog from "./components/FirstPageDialog";
import RenewalNotification from "./components/RenewalNotification";
import config from "../../config/config";
import Enums from "../../utils/Enums";
import Util from "../../utils/Util";
import FCMiniLeftDrawer from "../../components/FCMiniLeftDrawer";

smoothscroll.polyfill();

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navDrawerOpen: true,
      tokenRefreshTimeInMin: 1,
      auth: false,
      dialogQueue: ["RenewalNotification"],
      currentDialogIndex: 0,
      isRenewalNotificationOpen: false
    };
    this.mainRef = element => {
      this.main = element;
    };
    this.route = props.route;
  }

  componentWillMount() {
    const { user } = this.props;
    const { tokenRefreshTimeInMin } = this.state;
    if (user.loggedIn && user.auth) {
      const expire = moment(user.auth.expire_time);
      const diffInMinute = expire.diff(moment(), "minutes");
      if (diffInMinute < tokenRefreshTimeInMin) {
        this.props.logout();
        this.props.history.push("/");
      } else {
        this.setState({ auth: true });
        if (config.app.type === Enums.APP_TYPE.SALES) {
          this.props.getRenewPolicies();
        }
      }
    } else {
      this.props.logout();
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    const { user, quote } = this.props;
    if (user.loggedIn && user.auth && config.app.type === Enums.APP_TYPE.HR) {
      this.props.getClientAndPolicyListing();
    }

    const dialogMessage = config.firstPageDialogDetails;
    const showFirstPageDialog =
      (quote && quote?.id === null) ||
      quote?.stages === undefined ||
      (quote?.has_acceptance_file &&
        config.firstPageDialogEnabled &&
        Util.compareDate(quote.acceptance_date, config.disableSubmitSignDate) &&
        Util.isDialogNbstpEnabled(config.disableBefore, config.dialogAutoDisable));
    if (showFirstPageDialog) {
      this.setState({ dialogQueue: ["FirstPageDialog", ...this.state.dialogQueue] });
    }
  }

  handleDialogClose = () => {
    if (this.state.currentDialogIndex == 1) {
      this.props.updateIsRenewalNotificationOpen();
    }
    this.setState(prevState => ({ currentDialogIndex: prevState.currentDialogIndex + 1 }));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setState({
        navDrawerOpen: nextProps.width === "500px"
      });
    }
    try {
      if (this.props.location.pathname !== nextProps.location.pathname || nextProps.app.scrollToTop) {
        if (this.main) this.main.scrollTo(0, 0);
        this.props.scrollToTopFin();
      }
    } catch (error) {
      console.warn("ScrollTo not working for IE/Edge, or newer unknown tech");
    }
  }

  handleMenuToggle = () => {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    });
  };

  routeRendering = () => {
    const { navDrawerOpen, auth } = this.state;
    const { user } = this.props;
    if (user.loggedIn && user.auth && auth) {
      const filteredRoute = this.route.routes.filter(
        route => user.auth.scope.includes(route.access) || route.access === undefined
      );
      return renderRoutes(filteredRoute, { navDrawerOpen: navDrawerOpen });
    } else return <div />;
  };

  render() {
    const { navDrawerOpen, tokenRefreshTimeInMin, auth } = this.state;
    const { classes, history, user, quote, renewalPolicies } = this.props;
    const dialogMessage = config.firstPageDialogDetails;
    const showFirstPageDialog =
      ((quote && quote?.id === null) ||
        quote?.stages === undefined ||
        (quote?.has_acceptance_file &&
          Util.compareDate(quote.acceptance_date, config.disableSubmitSignDate) &&
          Util.isDialogNbstpEnabled(config.disableBefore, config.dialogAutoDisable))) &&
      config.firstPageDialogEnabled;

    //TODO: remove hardcoded avatar image
    let images = require("../../assets").default.images;

    if (!auth) return <div />;
    return (
      <div className={classes.root}>
        <SessionExpireDialog history={history} tokenRefreshTimeInMin={tokenRefreshTimeInMin} />
        <HeaderPlain brand={user.auth.pcode} />
        <div className={classes.appFrame}>
          {config.app.type === Enums.APP_TYPE.SALES ? (
            <FCMiniLeftDrawer
              drawerOpen={navDrawerOpen}
              routes={this.route.routes}
              handleMenuToggle={this.handleMenuToggle}
            />
          ) : (
            <MiniLeftDrawer
              avatar={images.avatar}
              drawerOpen={navDrawerOpen}
              routes={this.route.routes}
              handleMenuToggle={this.handleMenuToggle}
            />
          )}
          <main ref={this.mainRef} className={classNames(classes.content, navDrawerOpen && classes.menuShow)}>
            {this.routeRendering()}
          </main>
        </div>

        {this.state.dialogQueue.indexOf("FirstPageDialog") === this.state.currentDialogIndex &&
          config.app.type === Enums.APP_TYPE.SALES && (
            <FirstPageDialog message={dialogMessage} onClose={this.handleDialogClose} />
          )}

        {this.state.dialogQueue.indexOf("RenewalNotification") === this.state.currentDialogIndex &&
          renewalPolicies.pendingList.length > 0 &&
          !renewalPolicies.isRenewalNotificationOpened &&
          config.app.type === Enums.APP_TYPE.SALES && <RenewalNotification onClose={this.handleDialogClose} />}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.background.color
  },
  appFrame: {
    display: "flex",
    flex: 1,
    height: `calc(99vh - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: `64px`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.mixins.toolbar.minHeight}px`
    }
  },
  content: {
    width: "100%",
    height: "100%",
    marginLeft: `${theme.miniLeftDrawer.widthClosed}px`,
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    background: "url(" + assets.global.background + ")",
    backgroundSize: "cover",
    backgroundPosition: "100% 100%",
    padding: "10px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuShow: {
    marginLeft: `${theme.miniLeftDrawer.width}px`,
    padding: "10px"
  }
});

function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
    renewalPolicies: state.renewPolicies,
    quote: state.quote
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(productActions, dispatch),
    ...bindActionCreators(appActions, dispatch),
    ...bindActionCreators(clientAndPolicyListingActions, dispatch),
    ...bindActionCreators(renewalActions, dispatch),
    logout: () => dispatch(userActions.logout())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Auth));
