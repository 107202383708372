import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import assets from "../../assets";
import Enums from "../../utils/Enums";
import HeaderPlain from "../../components/HeaderPlain";
import Paper from "@mui/material/Paper";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import RefreshIcon from "@mui/icons-material/Refresh";
import MobileDetect from "mobile-detect";
// Styles
import "./Login.css";

import {
  appActions,
  coverageActions,
  productActions,
  quoteActions,
  userActions,
  clientAndPolicyListingActions
} from "../../actions";
import OtpDialog from "./OtpDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import SimpleMessageDialog from "../../components/SimpleMessageDialog";
import TncDialog from "../../components/hr/login/tncDialog";
import Util from "../../utils/Util";
import jsSHA from "jssha";

import captchaService from "../../services/captcha.service";
import config from "../../config/config";

import AlertTopBar from "../../components/AlertTopBar";
import PopupNotice from "../../components/PopupNotice";
import CiamResponseCode from "../../utils/CiamResponseCode";
import moment from "moment";

const SALES_AFTERLOGIN = "/auth/dashboard";
const HR_AFTERLOGIN = "/auth/claimAndPolicyListing";
const mobileDetect = new MobileDetect(window.navigator.userAgent);

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowError: false,
      username: "",
      password: "",
      showOTP: false,
      showForgotPassword: false,
      public_key: null,
      private_key: null,
      tx_id: null,
      random_key: null,
      showFirstTimeLogin: false,
      firstTimeLoginSubmitting: false,
      firstTimeLoginError: null,
      rsaErrorMessage: null,
      submitOTPError: false,
      isLoggingIn: false,
      showOTPDesc: false,
      showTrainingNotification: false,
      submitCaptchaError: false,
      captcha: {},
      submitOTPErrorMessage: "",
      ciamErrorCode: ""
    };
  }
  LOGIN_EVENT = "Login";

  GOOGLE_ANALYTICS_DETAILS = {
    MODULE: {
      GENERAL: "General"
    },
    FEATURE: {
      LOGIN: "Login"
    },
    JOURNEY: {
      LOGIN: "login"
    },
    EVENT: {
      LOGIN: "login"
    },
    EVENT_DETAILS: {
      LOGIN: "login"
    }
  };

  getUserName = () => {
    if (!this.state.username) return "";
    const appType = this.props.app.type;
    if (appType === Enums.APP_TYPE.HR) {
      return this.state.username.trim().toLowerCase();
    } else {
      return this.state.username.trim().toUpperCase();
    }
  };

  getUserNameHashed = () => {
    if (!this.state.username) return "";
    const appType = this.props.app.type;
    if (appType === Enums.APP_TYPE.HR) {
      let shaObj = new jsSHA("SHA-1", "TEXT");
      shaObj.update(this.state.username.trim().toLowerCase());
      return shaObj.getHash("B64");
    } else {
      return this.state.username.trim().toUpperCase();
    }
  };

  getEpochTime = () => {
    return Math.floor(new Date().getTime() / 1000);
  };

  getGoogleTagManagerDataLayer = () => {
    return window.dataLayer || [];
  };

  handleEventDetailsTracking = eventDetails => {
    this.getGoogleTagManagerDataLayer().push({
      ...eventDetails,
      start_time: this.getEpochTime()
    });
  };

  handleLoginTracking = () => {
    this.handleEventDetailsTracking({
      module: this.GOOGLE_ANALYTICS_DETAILS.MODULE.GENERAL,
      feature: this.GOOGLE_ANALYTICS_DETAILS.FEATURE.LOGIN,
      journey: this.GOOGLE_ANALYTICS_DETAILS.JOURNEY.LOGIN,
      event: this.GOOGLE_ANALYTICS_DETAILS.EVENT.LOGIN,
      event_detail: this.GOOGLE_ANALYTICS_DETAILS.EVENT_DETAILS.LOGIN,
      entry_point: null,
      start_time: this.getEpochTime()
    });
  };

  doCIAMLogin = () => {
    const { CIAMLogin, getRSA } = this.props;
    getRSA(this.state.username.trim(), this.state.password)
      .then(
        resp => {
          if (resp.success) {
            //let pem ="-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAhkgdGZqis4t97TxL9i7nWBzI3Yiv/nDPd4rGUSUflaWJU4/Wdlkq0Qm3J07c7xOstKX6M9RHby1c63JoXhiRUJTCMPb2BwyRf/iOWoi4lEVUGA8d/QQbAnreuEOXzOaKgIGb0JOrgpd2ESw5nIAprQ5adMpnQfQEm9v3iTkzb8gbjzBJUoLNsUYBLwLkApDbalexD8IIeGq77nxSdmAxXQ7Mlyb0n6VDevVFBWG2mnKt1XCJO0W5omyjRxt9RVy9epSlVGymOKIilqyoN830R9P4J8l5R+DgiA4OIspKMO0vwZNvSYA3tQBraiYndu8hReBjqEKEspetTFvyFaL/lvUHyS3mkc0r+TDb7gLfpx18xDYRN4MjNouCBEq3YXMehgWrpkXO/JagI5A9LmNfA9xpKaka2e5HSZtY/2Ux9diSiS06CcrPBmBh1+QqDL3biuQMdVu8Bzj8lxpRhSRqLzXuN6XpRQ+PBhLoSA7e56GiAOG2W33txBkbgaRPl/Al0/B9OvMM3/xqIeUVDM73rThhoPNM0LOBv2+kaXAcGy8d+N6dRnZzhvkSzDvK+gKTj2upUTZdQLk1UCfdhRXpXIwFnxSDKBURmbanLF/eairUQxH/nCbcLcmoI1xigtLTurViNSQ3rf492MTZ+uIy2fsh0dOMzQat5c2sZpY0ziMCAwEAAQ==-----END PUBLIC KEY-----";
            var myObjectInstance = new PACSCryptography(resp.data.public_key);
            var encryptedPW = myObjectInstance.encryptPassword(this.state.password);

            this.setState({
              public_key: resp.data.public_key,
              tx_id: resp.data.tx_id,
              random_key: resp.data.random_key,
              encrypted: encryptedPW,
              rsaErrorMessage: null,
              isShowError: false
            });
            const cnonce = Util.generateUUID();
            const chash = Util.getCHash(resp.data.public_key, cnonce);
            //do login
            return CIAMLogin(
              this.getUserName(),
              this.state.encrypted,
              this.state.random_key,
              this.state.tx_id,

              cnonce,
              chash,
              this.state.captcha.trx_id,
              this.state.captchaCode
            );
          }
        },
        error => {
          console.log("RSA Error : ", error);
          if (error.code !== 500) {
            this.setState({
              rsaErrorMessage: error.message || error.defaultMessage
            });
          }
          return Promise.reject(error);
        }
      )
      .then(
        response => {
          console.log("response", response);
          if (response.success) {
            this.setState({ otpRes: "", showOTPDesc: false });
            this.setState({ showOTP: true });
          }
        },
        error => {
          if (error.message === "unauthorized HR") {
            error["customMessage"] =
              "Unauthorized Access: Only authorized HR personnel are permitted to login to this portal";
            this.setState({
              submitCaptchaError: false,
              isLoggingIn: false,
              submitCaptchaErrorMessage: ""
            });
          }
          if (error.message === "Invalid Captcha trx id entered") {
            error["customCaptchaMessage"] = "Your code has expired, please refresh your captcha";
            error["customMessage"] = "Your code has expired, please refresh your captcha";
            this.props.user.error = false;
            this.setState({
              submitCaptchaError: true,
              isLoggingIn: false,
              submitCaptchaErrorMessage: error.customCaptchaMessage
            });
          } else if (error.message === "Invalid Captcha Code entered") {
            this.props.user.error = false;
            error["customCaptchaMessage"] = error.message;
            error["customMessage"] = error.message;
            this.setState({
              submitCaptchaError: true,
              isLoggingIn: false,
              submitCaptchaErrorMessage: error.customCaptchaMessage
            });
          } else {
            this.setState({
              submitCaptchaError: false,
              isLoggingIn: false,
              submitCaptchaErrorMessage: ""
            });
          }
          console.error("Login error:", error.customMessage, error);
          this.setState({ isLoggingIn: false });
        }
      );
  };

  handleSubmit = e => {
    e.preventDefault();
    //Both Sales and HR will be using CIAM login
    if (this.state.username.trim() === "") return;
    this.setState({ isLoggingIn: true });
    this.doCIAMLogin();
  };

  generateCaptcha = () => {
    captchaService.generateCaptcha().then(res => {
      if (res.data) {
        this.setState({
          captcha: res.data,
          captchaCode: ""
        });
      }
    });
  };

  refreshCaptcha = () => {
    let trxId = this.state.captcha.trx_id;
    captchaService.refreshCaptcha(trxId).then(res => {
      if (res.data) {
        this.setState({
          captcha: res.data,
          captchaCode: "",
          submitCaptchaErrorMessage: ""
        });
      } else {
        console.log("invalid request");
      }
    });
  };

  handleCaptchaChange = event => {
    let target = event.target;
    let value = target.value;
    this.setState({ captchaCode: value });
  };

  handleInputChange = event => {
    const target = event.target;
    this.setState({
      [target.id]: target.value
    });
  };

  handleCloseOtpDialog(otpMessage) {
    const subOtpErrMess = typeof otpMessage === "boolean" && otpMessage ? this.state.submitOTPErrorMessage : "";

    this.setState({
      showOTP: false,
      submitOTPError: false,
      submitOTPErrorMessage: subOtpErrMess,
      isLoggingIn: false,
      ciamErrorCode: ""
    });
  }

  handleOpenCloseForgotPassword() {
    this.setState({
      showForgotPassword: !this.state.showForgotPassword
    });
  }

  handleSubmitOTP(otp) {
    const { encrypted, random_key, tx_id } = this.state;
    this.props.postOTP(otp.trim(), encrypted, random_key, tx_id).then(
      response => {
        if (response.success) {
          this.props.updateToken(response.data);
          this.handleCloseOtpDialog();

          const appType = this.props.app.type;
          if (appType === Enums.APP_TYPE.SALES) {
            const tokenObj = Util.jwt_decode(response.data.access_token);
            if (tokenObj.scope.includes(Enums.SCOPE_TYPE.TRAINING_R)) {
              this.setState({ showTrainingNotification: true });
            } else {
              this.handleLoginSuccess(SALES_AFTERLOGIN);
            }
          } else if (appType === Enums.APP_TYPE.HR) {
            const tokenObj = Util.jwt_decode(response.data.access_token);
            //Fist time log for HR, show T & C
            if (tokenObj.scope.includes(Enums.SCOPE_TYPE.TC_W)) {
              this.setState({ showFirstTimeLogin: true });
            } else {
              //Initialize the product engine
              this.handleLoginSuccess(HR_AFTERLOGIN);
            }
          }
        }
      },
      error => {
        this.setState({
          submitOTPError: true,
          submitOTPErrorMessage: error.customMessage,
          ciamErrorCode: error.message
        });
      }
    );
  }

  handleCloseDialog = name => event => {
    if (name === "training_notification") {
      this.setState({ showTrainingNotification: false }, () => {
        this.props.logout();
      });
    }
  };

  handleAgreeTnc = (marketingConsent, tnc) => {
    marketingConsent = marketingConsent ? marketingConsent : false;
    this.props.agreeTnc({ marketingConsent, tnc }).then(
      response => {
        this.setState(
          {
            firstTimeLoginSubmitting: false,
            showFirstTimeLogin: false
          },
          () => {
            this.handleLoginSuccess(HR_AFTERLOGIN);
          }
        );
      },
      error => {
        console.error("Agree TNC error:", error);
        this.setState({ isLoggingIn: false });
      }
    );
  };

  handleLoginSuccess = async url => {
    this.handleLoginTracking(); //for google analytics fro login button.
    if (this.props.app.type === Enums.APP_TYPE.HR) {
      try {
        const response = await this.props.getClientAndPolicyListing();
        for (const policy of response.data) {
          await this.props.loadConfigByCodeAndVersion(policy.product_code, policy.product_version);
        }
        await this.props.getHRLoggedInfo();
        this.redirectTo(url);
      } catch (err) {
        console.error(err);
      }
    } else {
      //Initialize the product engine
      //pacseb 780
      try {
        await this.props.loadConfig();
        this.redirectTo(url);
      } catch (err) {
        console.error(err);
      }
    }
  };

  redirectTo = url => {
    this.setState({ isLoggingIn: false });
    this.props.history.push(url);
  };
  componentDidMount() {
    if (this.props.user.error) {
      this.props.user.error = null;
    }
  }

  componentWillMount() {
    if (this.props.user.loggedIn) {
      if (this.props.history.location.pathname === "/") {
        // logged in go to auth
        const AFTERLOGIN = this.props.app.type === Enums.APP_TYPE.HR ? HR_AFTERLOGIN : SALES_AFTERLOGIN;
        this.redirectTo(AFTERLOGIN);
      } else {
        //This will redirect you to your present page, which prevents you from redirecting back to login page after you have logged in.
        this.props.history.goBack();
      }
    } else {
      this.props.appActions.reset();
      this.generateCaptcha();
    }
  }

  render() {
    let images = assets.images;
    const { intl, user, classes, app } = this.props;
    const {
      isShowError,
      submitOTPErrorMessage,
      ciamErrorCode,
      showOTP,
      showForgotPassword,
      showFirstTimeLogin,
      firstTimeLoginSubmitting,
      firstTimeLoginError,
      rsaErrorMessage,
      isLoggingIn,
      showTrainingNotification
    } = this.state;

    let errorCustomMessage = user.error ? user.error.customMessage || user.error.defaultMessage : submitOTPErrorMessage;

    const errorCodesToHandle = [
      CiamResponseCode.OTP_EXPIRED.code,
      CiamResponseCode.MAX_OTP_FAILED.code,
      CiamResponseCode.OTP_EXPIRED.code_old,
      CiamResponseCode.FORGEROCK_ERROR.code
    ];

    if (errorCodesToHandle.includes(ciamErrorCode)) {
      this.handleCloseOtpDialog(true);
      errorCustomMessage = submitOTPErrorMessage;
      this.setState({ isShowError: true });
    }
    const isHR = app.type === Enums.APP_TYPE.HR;

    const { appVersionEnabled } = config;
    let bannerWrapperClasses = "";
    if (isHR) {
      bannerWrapperClasses = classNames(
        appVersionEnabled ? classes.bannerDisplayHR : classes.bannerDisplayWithoutVersionHR
      );
    } else {
      bannerWrapperClasses = classNames(
        appVersionEnabled ? classes.bannerDisplay : classes.bannerDisplayWithoutVersion
      );
    }

    return (
      <div
        className={classNames({
          [classes.loginHR]: isHR
        })}
      >
        {config.appVersionEnabled && (
          <div>
            <Typography
              variant="body2"
              className={config.bannerEnabled ? classes.version : classes.versionWithoutBanner}
            >
              {config.appVersionNumber}
            </Typography>
          </div>
        )}
        {config.bannerEnabled && (
          <div className={bannerWrapperClasses}>
            <AlertTopBar
              displayLogo={config.logoDisplay}
              type="tip"
              title={config.bannerTitle}
              listDescription={config.bannerDetails}
            />
          </div>
        )}
        <div className={config.bannerEnabled ? classes.loginWithBanner : classes.login}>
          {/* <div className="login"> */}
          {app.type === Enums.APP_TYPE.HR && <HeaderPlain />}
          <div className={classes.paperRootWrapper}>
            <Paper
              className={classNames(classes.paperRoot, app.type === Enums.APP_TYPE.SALES && classes.paperRootNoBox)}
              elevation={2}
            >
              {app.type === Enums.APP_TYPE.HR && <Typography variant="title">Log into PRUworks HR Portal</Typography>}
              <br />

              {app.type === Enums.APP_TYPE.SALES && (
                <div>
                  <img src={images.pru_work_logo} style={{ height: 70 }} className="App-logo" alt="logo" />
                </div>
              )}

              {app.type === Enums.APP_TYPE.SALES && (
                <Typography variant="subheading">
                  <i>
                    Log in username and password would be same as PSE. Should you have any difficulty to login, kindly
                    contact technical support at 6732 3850, or email to
                    <a href="mailto:sgp.helpdesk@prudential.com.sg"> sgp.helpdesk@prudential.com.sg.</a>
                    <br></br>
                  </i>
                </Typography>
              )}

              {/* Display error message */}
              {rsaErrorMessage && (
                <Typography variant="subheading" className={classes.errorMessage}>
                  {rsaErrorMessage}
                </Typography>
              )}
              {user.error || isShowError ? (
                <Typography variant="subheading" className={classes.errorMessage}>
                  <span
                    className={classes.text}
                    dangerouslySetInnerHTML={{
                      __html: errorCustomMessage
                    }}
                  />
                </Typography>
              ) : (
                " "
              )}

              <form onSubmit={this.handleSubmit}>
                <TextField
                  id="username"
                  fullWidth={app.type === Enums.APP_TYPE.HR}
                  label={intl.formatMessage({ id: "login.username" })}
                  placeholder={intl.formatMessage({ id: "login.username" })}
                  autoComplete="username"
                  value={this.state.username}
                  margin="normal"
                  onChange={this.handleInputChange}
                />
                <br />
                <TextField
                  id="password"
                  fullWidth={app.type === Enums.APP_TYPE.HR}
                  label={intl.formatMessage({ id: "login.password" })}
                  placeholder={intl.formatMessage({ id: "login.password" })}
                  type="password"
                  autoComplete="new-password"
                  value={this.state.password}
                  margin="normal"
                  onChange={this.handleInputChange}
                />
                <br />
                <br />
                {app.type === Enums.APP_TYPE.SALES && !config.disable_captcha_service && (
                  <div>
                    {mobileDetect.phone() !== null ? (
                      // <Grid className={classes.captchaImgMobile} container>
                      // <Grid item xs={2} sm={2} md={2}>
                      <div>
                        <img
                          src={`data:image/jpeg;base64,${this.state.captcha.captcha_image !== null &&
                            this.state.captcha.captcha_image}`}
                        />
                        {/* // </Grid>
                        // <Grid item xs={1} sm={1} md={1}> */}
                        <RefreshIcon
                          onClick={() => this.refreshCaptcha()}
                          style={{
                            fontSize: "40px",
                            cursor: "pointer",
                            paddingTop: "0px",
                            paddingLeft: "4px",
                            color: "#a9a9a9",
                            border: "1px solid #a9a9a9"
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        {/* // <Grid container className={classes.captchaImg}>
                      //   <Grid item xs={2} sm={2} md={2}> */}
                        <img
                          src={`data:image/jpeg;base64,${this.state.captcha.captcha_image !== null &&
                            this.state.captcha.captcha_image}`}
                        />
                        {/* // </Grid>
                        // <Grid item xs={1} sm={1} md={1}> */}
                        <RefreshIcon
                          onClick={() => this.refreshCaptcha()}
                          style={{
                            fontSize: "40px",
                            cursor: "pointer",
                            paddingTop: "0px",
                            paddingLeft: "4px",
                            color: "#a9a9a9",
                            border: "1px solid #a9a9a9"
                          }}
                        />
                        {/* </Grid>
                      </Grid> */}
                      </div>
                    )}

                    <TextField
                      id="captcha"
                      fullWidth={app.type === Enums.APP_TYPE.HR}
                      onChange={this.handleCaptchaChange}
                      label={"Enter captcha"}
                    />
                    {this.state.submitCaptchaError && (
                      <div>
                        <Typography variant="caption" style={{ color: "red", whiteSpace: "pre-line" }}>
                          {this.state.submitCaptchaErrorMessage}
                        </Typography>
                      </div>
                    )}
                    <br />
                    <br />
                  </div>
                )}
                {/* Fade In spinner when getting OTP */}
                <Fade
                  in={isLoggingIn}
                  style={{
                    transitionDelay: isLoggingIn ? "40ms" : "0ms"
                  }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
                <br />
                <Button variant="contained" color="primary" type="submit" disabled={isLoggingIn}>
                  {intl.formatMessage({ id: "login.button" })}
                </Button>
                <br /> {/* Forgot Password  */}
                {app.type === Enums.APP_TYPE.HR && (
                  <Button className={classes.forgotPassword} onClick={this.handleOpenCloseForgotPassword.bind(this)}>
                    Forgot password
                  </Button>
                )}
                {/* Tnc */}
                {app.type === Enums.APP_TYPE.HR && (
                  <TncDialog
                    open={showFirstTimeLogin}
                    submitting={firstTimeLoginSubmitting}
                    error={firstTimeLoginError}
                    handleAgree={this.handleAgreeTnc}
                  />
                )}
              </form>
            </Paper>
          </div>
          <OtpDialog
            show={showOTP}
            submitError={this.state.submitOTPError}
            description={
              this.state.showOTPDesc ? (
                <span>
                  An OTP has been sent to your registered Email Id and registered Mobile Number. <br />
                  Email Id: <b>{this.state.otpRes.emailId}</b>
                  <br />
                  Mobile Number: <b>{this.state.otpRes.contactNo}</b>
                  <br />
                </span>
              ) : (
                ""
              )
            }
            submitErrorMessage={this.state.submitOTPErrorMessage}
            submitHandler={this.handleSubmitOTP.bind(this)}
            closeHandler={this.handleCloseOtpDialog.bind(this)}
          />{" "}
          {app.type === Enums.APP_TYPE.HR && (
            <ForgotPasswordDialog
              show={showForgotPassword}
              forgotPassword={this.props.forgotPassword}
              app={app.type}
              closeHandler={this.handleOpenCloseForgotPassword.bind(this)}
            />
          )}
          {app.type === Enums.APP_TYPE.SALES && (
            <SimpleMessageDialog
              type="error"
              name="training-notification"
              title="Training Notification"
              description="Please complete the <B>EBS Basic Mandatory eLearn & Assessment</B> in order to submit PruTreasure Flexi III cases. Thank you."
              closeButtonText="OK"
              closeHandler={this.handleCloseDialog("training_notification")}
              open={showTrainingNotification}
              isHtml="true"
            />
          )}
        </div>
        {config.popUpLoginMessageEnabled && <PopupNotice message={config.popUpLoginMessage} />}
      </div>
    );
  }
}

const styles = theme => ({
  login: {
    textAlign: "center",
    marginTop: "5%"
  },
  loginWithBanner: {
    textAlign: "center",
    marginTop: "5%"
  },
  loginHR: {
    paddingTop: theme.spacing.unit * 3
  },
  errorMessage: {
    marginTop: theme.spacing.unit * 3,
    color: theme.palette.error.main
  },
  forgotPassword: {
    marginTop: theme.spacing.unit * 2
  },
  paperRootWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  paperRoot: {
    width: 300,
    padding: 24
  },
  paperRootNoBox: {
    boxShadow: "none",
    width: 420,
    position: "relative",
    top: -60
  },
  captchaImg: {
    marginLeft: theme.spacing.unit * 16
  },
  captchaImgMobile: {
    marginLeft: theme.spacing.unit * 13
  },
  bannerDisplay: {
    maxWidth: "90%",
    marginLeft: "5%",
    marginBottom: "5%"
  },
  bannerDisplayWithoutVersion: {
    marginTop: "100px",
    maxWidth: "90%",
    marginLeft: "5%",
    marginBottom: "5%"
  },
  bannerDisplayHR: {
    maxWidth: "90%",
    marginLeft: "5%"
  },
  bannerDisplayWithoutVersionHR: {
    marginTop: "100px",
    maxWidth: "90%",
    marginLeft: "5%"
  },
  version: {
    paddingTop: "5%",
    paddingRight: "5%",
    textAlign: "right",
    opacity: "50%"
  },
  versionWithoutBanner: {
    paddingTop: "5%",
    paddingRight: "5%",
    textAlign: "right",
    opacity: "50%"
  }
});

function mapStateToProps(state) {
  return { user: state.user, app: state.app };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(coverageActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(productActions, dispatch),
    ...bindActionCreators(clientAndPolicyListingActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch)
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Login)));
